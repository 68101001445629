<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - 週次活動実績</h1>
    </header>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="condition.department_id"
                    :options="options_department"
                    empty_option="全て"
                ></form-select>
            </div>
            <div class="col-12">
                <label class="form-label">集計期間</label>
                <div class="input-group">
                    <form-input-date
                        v-model="condition.date_from"
                    ></form-input-date>
                    <span class="input-group-text">〜</span>
                    <form-input-date
                        v-model="condition.date_to"
                    ></form-input-date>
                </div>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <div v-else class="table-responsive" style="width: 100%;">
            <template v-if="weekly_activities.activity_counts.length > 0">
                <p>※ 合計件数 (新規営業件数 / 再訪問営業件数)</p>
                <table class="table">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center col-6"></th>
                            <template v-for="term_label, index in weekly_activities.term_labels" :key="index">
                                <th class="text-center col-6 text-nowrap">{{ term_label.date_from }} ~<br>{{ term_label.date_to }} 週</th>
                            </template>
                            <th class="text-center col-6">合計</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="activity, index in weekly_activities.activity_counts" :key="activity.user_name">
                            <tr>
                                <td :class="{'table-secondary': isLast(index)}" class="align-middle text-center text-nowrap">{{ activity.user_name }}</td>
                                <template v-for="count, i in activity.weekly_counts" :key="i">
                                    <td :class="{'table-secondary': isLast(index)}" class="align-middle text-center">{{ `${count.new_sales_count + count.revisit_count}件 （${count.new_sales_count}/${count.revisit_count}）` }}</td>
                                </template>
                                <td :class="[isLast(index) ? 'table-secondary' : 'table-light']" class="align-middle text-center text-nowrap">{{ `${activity.new_sales_total + activity.revisit_total}件 （${activity.new_sales_total}/${activity.revisit_total}）` }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <p class="alert alert-secondary">※週次活動実績がありません</p>
            </template>
        </div>
    </section>

</template>

<script>
import FormSelect from '@/components/forms/FormSelect';
import FormInputDate from '@/components/forms/FormInputDate';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Department from '@/models/entities/department';

export default {
    name: 'ReportWeeklyActivity',
    components: {
        InlineLoader,
        FormInputDate,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索フォーム
            options_department: [],
            exists_options_department: false,

            // 検索条件
            condition: {
                department_id: null,
                date_from: null,
                date_to: null,
            },

            // 表示データ
            weekly_activities: {
                term_labels: [],
                activity_counts: [],
            },
        }
    },
    mounted() {
        this.fetchDepartments();
        this.initConditionDate();
    },
    methods: {
        fetchDepartments() {
            this.loading_count++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                // 事業部初期値セット
                if (this.$store.state.auth.department_id) {
                    this.condition.department_id = this.$store.state.auth.department_id;
                } else {
                    this.condition.department_id = this.options_department[0].value;
                }

                this.search();
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        // 検索
        search() {
            if (
                this.$helper.isBlank(this.condition.date_from)
                || this.$helper.isBlank(this.condition.date_to)
            ) {
                this.showErrorMessage('集計期間を設定してください');
                return true;
            }

            // ローディング開始
            this.loading_count++;
            // 表示データ初期化
            this.weekly_activities.activity_counts.splice(0);
            this.weekly_activities.term_labels.splice(0);

            this.$http.get('/report/weekly-activity', {
                params: this.condition,
            })
            .then(response => {
                this.weekly_activities.term_labels = response.data.term_labels;
                this.weekly_activities.activity_counts = response.data.activity_counts;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },
        thisMonday(date) {
            const this_year = date.getFullYear();
            const this_month = date.getMonth();
            const dates = date.getDate();
            const day_num = date.getDay();
            const this_monday = dates - day_num + 1;
            return new Date(this_year, this_month, this_monday);
        },
        initConditionDate() {
            let today = new Date();
            let first_of_month = new Date(today.getFullYear(), today.getMonth(), 1);
            let last_of_month = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            this.condition.date_from = this.$helper.ymdForInputDate(first_of_month);
            this.condition.date_to = this.$helper.ymdForInputDate(last_of_month);
        },
        isLast(index) {
            return index === this.weekly_activities.activity_counts.length - 1;
        }
    }
}
</script>
